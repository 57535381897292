import type { RouteLocation } from "vue-router";

export const setHouseRoute = (houseNum: number,
    locale: string, route: RouteLocation): string => {
    if (!houseNum) return;
    const pathArray: string[] = ['projects', route.params.path2 as string];
    const newPath: string = 'house-' + houseNum;
    pathArray.push(newPath);
    return "/" + locale + "/" + pathArray.join("/");
};

